import { FC } from 'react';
import { Box, makeStyles, Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import UserListMenu from '../components/userList/UserListMenu';
import UserGrid from '../components/userList/UserGrid';

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 24,
    marginBottom: 20,
    color: '#3F3F3F',
  },
  inviteBtn: {
    fontSize: 14,
    borderRadius: 4,
    height: 36,
    background: '#535D6D',
    padding: '10px 20px',
    '&:hover': {
      background: '#36454f',
    },
  },
  root: {
    background: '#eee',
    minHeight: '92vh',
  },
}));

const UserList: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box px={30} pt={10} className={classes.root}>
      <Box display="flex">
        <Grid container spacing={4}>
          {/* TODO : Revisit the responsiveness when group page comes in, hiding the userlist menu on ipad for now */}
          <Grid item xs={false} md={2}>
            <Box display={{ xs: 'none', md: 'block' }} mt={14}>
              <UserListMenu />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.header}>Render Networks Users</Typography>
                <Button
                  size="large"
                  className={classes.inviteBtn}
                  onClick={() => {
                    history.push('/invite-user');
                  }}
                >
                  INVITE USERS
                </Button>
              </Box>
              <UserGrid />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserList;
