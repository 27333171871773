import { FC } from 'react';
import {
  Box,
  makeStyles,
  Button,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  helperText: {
    color: 'grey',
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: 520,
    opacity: 0.8,
  },
  fileName: {
    maxWidth: '20em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginRight: 10,
    verticalAlign: 'bottom',
  },
  success: {
    color: 'green',
  },
  error: {
    color: '#ffa242',
  },
}));

const ProcessingResults: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const rows = [
    { fileName: 'Design-Package-PQR.zip', status: 'Success', error: '' },
    { fileName: 'Design-Package-ABC.zip', status: 'Success', error: '' },
    {
      fileName: 'Design-Package.zip-XYZ',
      status: 'Issues Found',
      error:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit Pharetra et nisl metus lectus ac lorem duis urna id, pellentesque urna tellus arcu , pellentesque urna tellus arcu. Ultrices imperdiet lectus orci congue faucibus nisl augue Id consectetur quis amet, pellentesque urna tellus arcu Dictumst feugiat scelerisque dolor, in pharetra viverra eu ac.',
    },
  ];
  const redirect = (status: string) => {
    const route = status === 'Success' ? '/blueprints' : 'upload';
    history.push(route);
  };
  return (
    // making it responsive for tablets
    <Box mt={12} width={{ sm: '80vw', md: '60vw' }} mx="auto">
      <Box mt={12} mb={4}>
        <Typography variant="h2">Design Processing Results</Typography>
      </Box>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.helperText}>File Name</TableCell>
              <TableCell className={classes.helperText} align="left">
                Processing Status
              </TableCell>
              <TableCell className={classes.helperText} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <>
                <TableRow key={`${row.fileName} ${row.status}`}>
                  <TableCell className={classes.fileName} component="th" scope="row">
                    {row.fileName}
                  </TableCell>
                  <TableCell align="left">
                    {row.status === 'Success' ? (
                      <DoneIcon className={`${classes.icon} ${classes.success}`} />
                    ) : (
                      <ReportProblemOutlinedIcon className={`${classes.icon} ${classes.error}`} />
                    )}
                    {row.status}
                  </TableCell>
                  <TableCell align="center">
                    <Box width="70%" margin="auto">
                      <Button
                        onClick={() => redirect(row.status)}
                        fullWidth
                        variant="outlined"
                        size="small"
                      >
                        {row.status === 'Success' ? 'Preview Blueprint' : 'Upload Again'}
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
                {row.status !== 'Success' && (
                  <TableCell colSpan={3}>
                    <Alert icon={false} severity="warning">
                      <AlertTitle>Render Notes:</AlertTitle>
                      {row.error.split('.').map((error) => {
                        if (error) {
                          return <li>{error}</li>;
                        }
                        return null;
                      })}
                    </Alert>
                  </TableCell>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProcessingResults;
