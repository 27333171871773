import { Box, Button, Grid } from '@material-ui/core';
import { useState, FC } from 'react';
import useSWR from 'swr';
import FileUpload from '../components/fileUpload/FileUpload';
import Loader from '../components/loader/Loader';
import {
  deleteService,
  fetcher,
  getService,
  postMultipartService,
  postService,
  putService,
} from '../service/ApiService';
import { MultipartFormData } from '../service/Helper';
import { useStore } from './App';

const dummyUrl = 'https://jsonplaceholder.typicode.com';

const useData = () => {
  const { data, error } = useSWR([dummyUrl], (url) => fetcher(url, { route: ['users'] }));
  return {
    data,
    loading: !error && !data,
    error,
  };
};

const Test: FC = () => {
  const [response, setResponse] = useState({ get: {}, post: {}, put: {}, delete: {} });
  const [showLoader, hideLoader] = useStore((state) => [state.showLoader, state.hideLoader]);
  const { data, error, loading } = useData();

  const get = () => {
    showLoader();
    getService({ route: ['users'] }, { baseUrl: dummyUrl })
      .then((r: any) => {
        hideLoader();
        const res = { ...response, ...{ get: r } };
        setResponse(res);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const post = () => {
    showLoader();
    postService(
      { route: ['users'] },
      {
        title: 'Test POST',
        body: 'Success',
        userId: 1,
      },
      {
        baseUrl: dummyUrl,
      },
    )
      .then((r: any) => {
        hideLoader();
        const res = { ...response, ...{ post: r } };
        setResponse(res);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const put = () => {
    showLoader();
    putService(
      { route: ['users', '1'] },
      {
        title: 'Test PUT',
        body: 'Success',
        userId: 1,
      },
      {
        baseUrl: dummyUrl,
      },
    )
      .then((r: any) => {
        hideLoader();
        const res = { ...response, ...{ put: r } };
        setResponse(res);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const remove = () => {
    showLoader();
    deleteService({ route: ['users', '1'] }, { baseUrl: dummyUrl })
      .then(() => {
        hideLoader();
        const res = { ...response, ...{ delete: 'Test DELETE Success' } };
        setResponse(res);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const onUpload = (file: any) => {
    showLoader();
    const formData: MultipartFormData[] = [];
    formData.push({
      name: 'file',
      content: file,
    });
    postMultipartService({ route: ['post'] }, formData, { baseUrl: 'http://httpbin.org' })
      .then(() => {
        hideLoader();
        const res = { ...response, ...{ delete: 'Test DELETE Success' } };
        setResponse(res);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  if (error) return <div>Failed to load</div>;

  return (
    <Box>
      <Loader open={loading} />
      {/* File Upload */}
      <FileUpload acceptedTypes="" onFileSelect={onUpload} />
      {/* get with SWR */}
      <Grid container>
        <Grid xs={6}>
          <Box m={4}>
            <Button fullWidth size="large">
              GET RESPONSE SWR
            </Button>
          </Box>
          <Box maxHeight={350} overflow="scroll" boxShadow={2} p={2} m={4}>
            <pre>{JSON.stringify(data, null, 4)}</pre>
          </Box>
        </Grid>
        {/* get without SWR */}
        <Grid xs={6}>
          <Box m={4}>
            <Button onClick={get} fullWidth size="large">
              MAKE A GET CALL
            </Button>
          </Box>
          <Box minHeight={350} maxHeight={350} overflow="scroll" boxShadow={2} p={2} m={4}>
            <pre>{JSON.stringify(response.get, null, 4)}</pre>
          </Box>
        </Grid>
      </Grid>
      {/* post call */}
      <Grid container>
        <Grid xs={4}>
          <Box m={4}>
            <Button fullWidth size="large" onClick={post}>
              MAKE A POST CALL
            </Button>
          </Box>
          <Box minHeight={200} boxShadow={2} p={2} m={4}>
            <pre>{JSON.stringify(response.post, null, 4)}</pre>
          </Box>
        </Grid>
        {/* put call */}
        <Grid xs={4}>
          <Box m={4}>
            <Button fullWidth size="large" onClick={put}>
              MAKE A PUT CALL
            </Button>
          </Box>
          <Box minHeight={200} boxShadow={2} p={2} m={4}>
            <pre>{JSON.stringify(response.put, null, 4)}</pre>
          </Box>
        </Grid>
        {/* delete call */}
        <Grid xs={4}>
          <Box m={4}>
            <Button fullWidth size="large" onClick={remove}>
              MAKE A DELETE CALL
            </Button>
          </Box>
          <Box minHeight={200} boxShadow={2} p={2} m={4}>
            <pre>{JSON.stringify(response.delete, null, 4)}</pre>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Test;
