import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import './App.css';
import Loader from './components/loader/Loader';
import AppContainer, { useStore } from './containers/App';
import theme from './theme/Theme';

const App: FC = () => {
  const [loading] = useStore((state) => [state.loading]);

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Loader open={loading} />
          <SWRConfig value={{ dedupingInterval: 5000 }}>
            <AppContainer />
          </SWRConfig>
        </ThemeProvider>
      </Router>
    </div>
  );
};

export default App;
