import { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import create from 'zustand';
import { combine } from 'zustand/middleware';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import Login from './Login';
import Layout from '../components/navigation/Layout';
import EmailVerified from './EmailVerified';
import Onboarding from './Onboarding';
import Home from './Home';
import ProcessingResult from './ProcessingResult';
import Test from './Test';
import UploadConfirmation from './UploadConfirmation';
import ViewBlueprints from './ViewBlueprints';
import AccountActivated from './ActivationConfirmation';
import UserList from './UserList';
import Reports from './Reports';

export const useStore = create(
  combine({ loading: false }, (set) => ({
    showLoader: () => set(() => ({ loading: true })),
    hideLoader: () => set(() => ({ loading: false })),
  })),
);

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_DOMAIN,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  postLogoutRedirectUri: `${window.location.origin}/login`,
  scopes: ['openid', 'profile'],
});

const App: FC = () => {
  const history = useHistory();
  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={() => {
        history.push('/login');
      }}
    >
      <Switch>
        <Layout>
          <SecureRoute path="/" exact component={Reports} />
          <SecureRoute path="/upload-design" exact component={Home} />
          <SecureRoute path="/confirm-upload" exact component={UploadConfirmation} />
          <SecureRoute path="/email-verified" exact component={EmailVerified} />
          <SecureRoute path="/results" exact component={ProcessingResult} />
          <SecureRoute path="/blueprints" exact component={ViewBlueprints} />
          <SecureRoute path="/test" exact component={Test} />
          <SecureRoute path="/account-activated" exact component={AccountActivated} />
          <SecureRoute path="/users" exact component={UserList} />
          <SecureRoute path="/reports" exact component={Reports} />
          <Route path="/signup" exact component={Onboarding} />
          <Route path="/login" exact component={Login} />
          <Route path="/login/callback" exact component={LoginCallback} />
        </Layout>
      </Switch>
    </Security>
  );
};

export default App;
