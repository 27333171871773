import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useHistory } from 'react-router';
import { Pagination } from '@material-ui/lab';
import mockData from './MockData';

const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    borderRadius: 4,
  },
  detailCell: {
    textDecoration: 'underline',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  gridHeader: {
    background: '#535D6D',
    color: 'white',
    border: 'none',
    fontSize: 16,
    padding: '10px !important',
  },
  pagination: {
    paddingRight: 20,
    marginTop: -10,
  },
  grid: {
    color: '#3F3F3F',
    borderRadius: 4,
    // minHeight: "71vh"
  },
  greyText: {
    color: '#999',
  },
}));

const getColumns = (history: any, classes: any) => [
  {
    field: 'email',
    headerName: 'Email',
    width: 245,
    sortable: false,
    headerClassName: classes.gridHeader,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 120,
    sortable: false,
    headerClassName: classes.gridHeader,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 120,
    sortable: false,
    headerClassName: classes.gridHeader,
  },
  {
    field: 'userType',
    headerName: 'User Type',
    cellClassName: classes.greyText,
    sortable: false,
    align: 'center' as any,
    headerAlign: 'center' as any,
    headerClassName: classes.gridHeader,
  },
  {
    field: 'lastActive',
    headerName: 'Last Active',
    cellClassName: classes.greyText,
    width: 110,
    sortable: false,
    align: 'center' as any,
    headerAlign: 'center' as any,
    headerClassName: classes.gridHeader,
  },
  {
    field: 'status',
    cellClassName: classes.greyText,
    headerName: 'Status',
    sortable: false,
    align: 'center' as any,
    headerAlign: 'center' as any,
    headerClassName: classes.gridHeader,
  },
  {
    field: 'action',
    headerName: 'Action',
    headerAlign: 'center' as any,
    sortable: false,
    headerClassName: classes.gridHeader,
    renderCell: (c: any) => (
      <Box
        className={classes.detailCell}
        onClick={() => {
          history.push(`user/${c.row.id}`);
        }}
      >
        Details
      </Box>
    ),
  },
];

function CustomPagination(props: any) {
  const { state, api } = props;
  const classes = useStyles();

  return (
    <Pagination
      className={classes.pagination}
      shape="rounded"
      showFirstButton
      showLastButton
      page={state.pagination.page}
      count={state.pagination.pageCount}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      onChange={(event, value) => api.current.setPage(value)}
    />
  );
}

const UserGrid: FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <DataGrid
        rows={mockData}
        className={classes.grid}
        columns={getColumns(history, classes)}
        pageSize={10}
        rowsPerPageOptions={[10, 20]}
        disableColumnMenu
        density="compact"
        rowHeight={65}
        headerHeight={60}
        hideFooterSelectedRowCount
        hideFooterRowCount
        page={page}
        onPageChange={(params) => {
          setPage(params.page);
        }}
        components={{
          Pagination: CustomPagination,
        }}
        autoHeight
      />
    </Box>
  );
};

export default UserGrid;
