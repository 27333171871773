import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import OktaSignInWidget from '../components/login/OktaSignInWidget';
import RenderBackground from '../assets/images/bg.jpg';

const useStyles = makeStyles(() => ({
  background: {
    backgroundImage: `url(${RenderBackground})`,
    height: '100vh',
    backgroundSize: 'cover',
  },
}));

const Login: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.background}>
      <OktaSignInWidget />
    </div>
  );
};
export default Login;
