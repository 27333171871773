import { FC } from 'react';
import { Box, makeStyles, Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';
import { useHistory } from 'react-router-dom';
import Illustration from '../assets/images/verified.svg';

const useStyles = makeStyles(() => ({
  successIcon: {
    marginRight: '10px',
  },
  illustration: {
    width: '12vw',
    textAlign: 'center',
    margin: '50px 40px',
  },
  helperText: {
    color: 'grey',
  },
}));

const EmailVerified: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box py={6} mt={8} width="60vw" mx="auto" textAlign="center">
      <img src={Illustration} className={classes.illustration} alt="uploaded" />
      <br />
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <CheckIcon color="primary" fontSize="large" className={classes.successIcon} />
        <p>Thank You. You’re Verified.</p>
      </Box>
      <br />
      <p className={classes.helperText}>
        Converting the design package into a network design usually takes anywhere between 48 hrs to
        5 days (depending on the complexity) Once done, we will send an email to example.@gmail.com
      </p>
      <br />
      <br />
      <Button
        size="large"
        onClick={() => {
          history.push('/results');
        }}
      >
        Back to website
      </Button>
    </Box>
  );
};

export default EmailVerified;
