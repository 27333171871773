/* eslint-disable max-lines */
import { FC, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Container,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  Badge,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import TimezoneSelect from 'react-timezone-select';
import onboardingStyles from '../components/onboarding/Styles';
import { color } from '../theme/Theme';

const Onboarding: FC = () => {
  const classes = onboardingStyles();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [org, setOrg] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [visibility, setVisibilty] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<any>({});
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [submit, setSubmit] = useState<boolean>(false);
  const history = useHistory();

  const validatePassword = (rule: string) => {
    switch (rule) {
      case 'LowerCase':
        return /[a-z]/g.test(password);
      case 'UpperCase':
        return /[A-Z]/g.test(password);
      case 'Length':
        return password.length >= 8;
      case 'SpecialChar':
        return /[!@#$%^&*]/g.test(password);
      case 'Number':
        return /[0-9]/g.test(password);
      default:
        return new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})').test(
          password,
        );
    }
  };

  const validateForm = () => {
    return !!(firstName && lastName && validatePassword('all'));
  };

  const onSubmit = () => {
    setSubmit(true);
    if (validateForm()) {
      try {
        console.log('API call');
        history.push('account-activated');
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  return (
    <Container>
      <Box mt={4} display="flex" justifyContent="center">
        <Grid>
          <Grid container>
            <Grid item sm={3} md={4}>
              <Box p={4} pt={16}>
                <Typography className={classes.header} color="primary">
                  Hey, we just need a few more details
                </Typography>
                <Box mb={4} />
                <p className={classes.secondaryText}>
                  You have been invited to active an account using{' '}
                  <strong>user.email@example.com</strong>.
                </p>
                <Box mb={8} />
                <p className={classes.secondaryText}>
                  To use the platform to its full potential, create an account. Check your details
                  are correct, and fill any information that may be missing.
                </p>
              </Box>
            </Grid>
            <Grid className={classes.verDivider}>
              <Divider variant="middle" orientation="vertical" />
            </Grid>
            <Grid item sm={8} md={7} className={classes.rightSec}>
              <Box p={2} pt={14}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      color="secondary"
                      fullWidth
                      error={!firstName && submit}
                      inputProps={{
                        className: classes.inputText,
                        'data-cy': 'first-name',
                        endAdornment: (
                          <>
                            {!firstName && submit && (
                              <ErrorOutlineIcon htmlColor={color.warning} fontSize="small" />
                            )}
                          </>
                        ),
                      }}
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      label="First Name*"
                    />
                    {!firstName && submit && (
                      <p className={`${classes.helperText} ${classes.error}`}>
                        Please enter a valid first name
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => setOrg(e.target.value)}
                      value={org}
                      color="secondary"
                      fullWidth
                      inputProps={{
                        className: classes.inputText,
                        'data-cy': 'org-name',
                      }}
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      label="Organization Name"
                    />
                    <p className={classes.helperText}>
                      Enter the name of the organisation you’re employed by
                    </p>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      color="secondary"
                      fullWidth
                      error={!lastName && submit}
                      inputProps={{
                        className: classes.inputText,
                        'data-cy': 'last-name',
                        endAdornment: (
                          <>
                            {!lastName && submit && (
                              <ErrorOutlineIcon htmlColor={color.warning} fontSize="small" />
                            )}
                          </>
                        ),
                      }}
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      label="Last Name*"
                    />
                    {!lastName && submit ? (
                      <Box mt={3} className={`${classes.helperText} ${classes.error}`}>
                        {!lastName && submit ? 'Please enter a valid last name' : ''}
                      </Box>
                    ) : (
                      <Box mt={7} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                      color="secondary"
                      fullWidth
                      inputProps={{
                        className: classes.inputText,
                        'data-cy': 'role-title',
                      }}
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      label="Role Title"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Box mb={4} />
                    <PhoneInput
                      containerClass={classes.phoneContainer}
                      inputClass={classes.phoneInput}
                      enableSearch
                      disableSearchIcon
                      preferredCountries={['au', 'us']}
                      country="au"
                      isValid={!(!phone && submit)}
                      searchPlaceholder="Search"
                      value={phone}
                      inputProps={{
                        name: 'phone',
                        'data-cy': 'phone-number',
                      }}
                      onChange={(p) => setPhone(p)}
                    />
                    {!phone && submit ? (
                      <div className={`${classes.helperText} ${classes.error}`}>
                        Please enter a valid phone number
                      </div>
                    ) : (
                      <div className={`${classes.helperText} ${classes.phoneHelper}`}>
                        Enter your mobile/cell number *
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                      color="secondary"
                      fullWidth
                      inputProps={{
                        className: classes.inputText,
                        'data-cy': 'based-in',
                      }}
                      className={classes.inputField}
                      size="small"
                      variant="outlined"
                      label="Based in"
                    />
                    <p className={classes.helperText}>Enter the location you’re based in</p>
                  </Grid>
                </Grid>
                <Box mb={2} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box mt="15px" ml="5px">
                      <TimezoneSelect
                        value={timezone}
                        labelStyle="abbrev"
                        className={classes.roboto}
                        onChange={setTimezone}
                        data-cy="time-zone"
                        placeholder="Start typing to search.."
                      />
                    </Box>
                    <Box mb={1} />
                    <div className={classes.helperText}>Select your time zone</div>
                  </Grid>
                  <Grid item xs={6}>
                    <Box mb={2.5} />
                    <Box ml={1}>
                      <OutlinedInput
                        color="secondary"
                        style={{ paddingRight: 0 }}
                        margin="dense"
                        placeholder="Password *"
                        value={password}
                        error={!validatePassword('AllRules') && submit}
                        onChange={(e) => setPassword(e.target.value)}
                        type={visibility ? 'text' : 'password'}
                        inputProps={{
                          className: classes.inputText,
                          'data-cy': 'password',
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className={classes.showBtn}
                              onClick={() => {
                                setVisibilty(!visibility);
                              }}
                            >
                              {visibility ? (
                                <Typography
                                  className={`${classes.showHideBtn} ${
                                    !validatePassword(password) && submit ? classes.error : ''
                                  }`}
                                  variant="caption"
                                >
                                  Hide
                                  <VisibilityOff className={classes.iconBtn} fontSize="small" />
                                </Typography>
                              ) : (
                                <Typography
                                  className={`${classes.showHideBtn} ${
                                    !validatePassword(password) && submit ? classes.error : ''
                                  }`}
                                  variant="caption"
                                >
                                  Show
                                  <Visibility className={classes.iconBtn} fontSize="small" />
                                </Typography>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        className={classes.passwordField}
                        fullWidth
                      />
                    </Box>
                    <Box pt={4} pl={6}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box>
                            <Badge
                              color="secondary"
                              badgeContent=" "
                              variant="dot"
                              classes={{
                                dot: validatePassword('LowerCase')
                                  ? classes.check
                                  : classes.uncheck,
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            />
                            <span className={classes.passwordStength}>Lower case</span>
                          </Box>
                          <Box>
                            <Badge
                              color="secondary"
                              badgeContent=" "
                              variant="dot"
                              classes={{
                                dot: validatePassword('UpperCase')
                                  ? classes.check
                                  : classes.uncheck,
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            />
                            <span className={classes.passwordStength}>Upper case</span>
                          </Box>
                          <Box>
                            <Badge
                              color="secondary"
                              badgeContent=" "
                              variant="dot"
                              classes={{
                                dot: validatePassword('Length') ? classes.check : classes.uncheck,
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            />
                            <span className={classes.passwordStength}>Min 8 characters</span>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box>
                            <Badge
                              color="secondary"
                              badgeContent=" "
                              variant="dot"
                              classes={{
                                dot: validatePassword('Number') ? classes.check : classes.uncheck,
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            />
                            <span className={classes.passwordStength}>Number</span>
                          </Box>
                          <Box>
                            <Badge
                              color="secondary"
                              badgeContent=" "
                              variant="dot"
                              classes={{
                                dot: validatePassword('SpecialChar')
                                  ? classes.check
                                  : classes.uncheck,
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            />
                            <span className={classes.passwordStength}>Special character</span>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box mb={10} />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox}
                      data-cy="agree-tos"
                      className={!checkbox && submit ? classes.error : ''}
                      onChange={(e: any) => setCheckbox(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span
                      className={`${classes.terms} ${!checkbox && submit ? classes.error : ''}`}
                    >
                      By selecting the box you’re confirming that you understand and agree to our{' '}
                      <span />
                      <a
                        className={classes.tosLink}
                        href="https://rendernetworks.com/tos/"
                        target="blank"
                      >
                        Terms of Service.
                      </a>
                    </span>
                  }
                />
                <Box mb={6} />
                <Button
                  color="primary"
                  onClick={onSubmit}
                  data-cy="activate-account"
                  className={classes.submitBtn}
                  fullWidth
                  size="large"
                >
                  ACTIVATE ACCOUNT
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Onboarding;
