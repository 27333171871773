import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CloudDoneOutlined';
import Illustration from '../assets/images/mail.svg';
import { color } from '../theme/Theme';

const useStyles = makeStyles(() => ({
  successIcon: {
    marginRight: '10px',
  },
  resendText: {
    marginBottom: 0,
  },
  resendLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginTop: 0,
    marginLeft: 5,
  },
  illustration: {
    width: '16vw',
    textAlign: 'center',
    margin: '50px 40px',
  },
  helperText: {
    color: color.charcoal,
    margin: '5px 0px',
  },
}));

type RouterState = {
  email: string;
  fileName: string;
};

const UploadConfirmation: FC = () => {
  const classes = useStyles();
  const location = useLocation<RouterState>();
  const { email, fileName } = location.state || {
    email: '',
    fileName: '',
  };
  const history = useHistory();

  const handleResendEmail = () => {
    history.push('email-verified');
  };

  return (
    <Box py={6} mt={16} width="60vw" mx="auto" textAlign="center">
      <Box display="flex" justifyContent="center" alignItems="center">
        <CheckIcon color="primary" fontSize="large" className={classes.successIcon} />
        <p>Your design package '{fileName}' has been uploaded successfully.</p>
      </Box>
      <br />
      <Typography>Please verify your email address</Typography>
      <p className={classes.helperText}>
        For us to process the file, please click on the link that has just been sent to your email
        address (<b>{email}</b>) to verify your email.
      </p>
      <br />
      <img src={Illustration} className={classes.illustration} alt="uploaded" />
      <p className={classes.resendText}>
        Can't find the email?
        <span data-cy="resend-email" className={classes.resendLink} onClick={handleResendEmail}>
          Resend Email
        </span>
      </p>
    </Box>
  );
};

export default UploadConfirmation;
