/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ChangeEvent, FC, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { color } from '../../theme/Theme';

const useStyles = makeStyles(() => ({
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  inputLabel: {
    cursor: 'pointer',
    textAlign: 'center',
    display: 'inline-block',
  },
  inputButton: {
    pointerEvents: 'none',
    padding: '10px 40px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    color: 'grey',
  },
  fileName: {
    padding: 20,
    fontSize: 16,
  },
  remove: {
    fontSize: 12,
  },
  errorText: {
    fontSize: 13,
    marginTop: 10,
    color: color.warning,
  },
}));

export const calculateFileSize = (s: number): string => {
  const units = ['Bytes', 'KB', 'MB', 'GB'];
  let i = 0;
  let size = s;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  const exactSize = `${Math.round(size * 100) / 100} ${units[i]}`;
  return exactSize;
};

interface PropsFromParent {
  acceptedTypes: string;
  onFileSelect: (file: File | null) => void;
}

const FileUpload: FC<PropsFromParent> = (props) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileSize, setFileSize] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { onFileSelect, acceptedTypes } = props;

  function handleFileUploaded(evt: ChangeEvent<HTMLInputElement>): void {
    const file: File = evt.target.files![0];
    // limiting files to type zip and size 20Mb
    if (
      file.size / 1024 / 1024 > 20 ||
      (file.type !== 'application/zip' && file.type !== 'application/x-zip-compressed')
    ) {
      setError('Please select a zip file below 20Mb');
      setFileName('');
    } else {
      setError(null);
      setFileSize(calculateFileSize(file.size));
      setFileName(file.name);
      onFileSelect(file);
    }
  }

  const classes = useStyles();

  return (
    <div style={{ padding: '25px 0px' }}>
      <input
        type="file"
        accept={acceptedTypes}
        key={fileName}
        id="file-input"
        data-cy="file-input"
        className={classes.inputFile}
        onChange={handleFileUploaded}
      />
      {fileName && !error && (
        <Box textAlign="center">
          <Typography className={classes.fileName} variant="caption" data-cy="file-name">
            {fileName} ({fileSize})
          </Typography>
          <IconButton
            data-cy="remove-button"
            onClick={() => {
              setFileName('');
              onFileSelect(null);
            }}
            size="small"
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      )}
      {!fileName && (
        <label className={classes.inputLabel} htmlFor="file-input">
          <Button
            startIcon={<CloudUploadIcon color="disabled" />}
            variant="outlined"
            className={classes.inputButton}
          >
            Select File
          </Button>
        </label>
      )}
      {!!error && (
        <Box textAlign="center">
          <Typography className={classes.errorText} data-cy="upload-error">
            <i>{error}</i>
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default FileUpload;
