import { AxiosRequestConfig } from 'axios';
import HttpContentType from './HttpContentType';

interface ContentRef {
  readonly contentType: any;
  readonly name: string;
}

export interface Content {
  readonly contentRef: ContentRef;
  readonly size: number;
  readonly data: Blob | string | null;
  readonly dataBase64?: string | undefined | null;
}

export interface MultipartFormData {
  readonly name: string;
  readonly content: Content | string;
}

export const prepareMultiPartForm = (data: MultipartFormData[]): FormData => {
  const formData = new FormData();
  for (const item of data) {
    if (typeof item.content === 'string') {
      // Json string
      formData.append(item.name, item.content);
    } else {
      // Blob
      formData.append(item.name, item.content.data!, item.content.contentRef.name);
    }
  }

  return formData;
};

export class QueryPath {
  // /route1/route2...
  public readonly route?: string[] | undefined | null;
  // ?name1=value1&name2=value2...
  // Null, undefined or empty string won't be appended to the url.
  public readonly query?:
    | { [name: string]: string | number | boolean | undefined | null }
    | undefined
    | null;
}

export const getUrl = (path: QueryPath, baseUrl?: string): string => {
  let url = baseUrl ?? '';
  if (path) {
    if (path.route && path.route.length > 0) {
      for (const route of path.route) {
        if (route && route !== 'undefined') {
          url += `/${route}`;
        }
      }
    }

    if (path.query) {
      let separator = '?';
      for (const name in path.query) {
        if (path.query[name]) {
          url += `${separator}${encodeURI(name)}=${encodeURI(path.query[name]!.toString())}`;
          separator = '&';
        }
      }
    }
  }
  return url;
};

export const getConfig = (
  contentType?: HttpContentType,
  authToken?: string,
): AxiosRequestConfig => {
  const oktaObj = JSON.parse(localStorage.getItem('okta-token-storage')!);
  const oktaToken = oktaObj.accessToken.accessToken;
  const headers =
    authToken || localStorage.getItem('authToken') || oktaToken
      ? {
          'Content-Type': contentType?.toString(),
          'Authorization': `Bearer ${authToken || localStorage.getItem('authToken') || oktaToken}`,
        }
      : {
          'Content-Type': contentType?.toString(),
        };

  return {
    headers,
  };
};

export const processError = (error: any): Error => {
  const errorCode = error.response ? error.response.status || 500 : 401;
  switch (errorCode) {
    case 404:
      return new Error('(404) The request is not found');
    case 422:
      return new Error('(422) Invalid request');
    case 500:
      return new Error('(500) Internal server error');
    case 400: {
      if (error.response.data.errors) {
        const err = error.response.data.errors;
        if (err.message) {
          return new Error(err.message.toString());
        }
        if (err.Message) {
          return new Error(err.Message.toString());
        }
        return new Error(err.toString());
      }
      return new Error('(400) Invalid request');
    }
    default:
      return error; // eslint-disable-line @typescript-eslint/no-unsafe-return
  }
};

export async function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}
