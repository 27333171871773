import { FC } from 'react';
import { Box, makeStyles, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  successIcon: {
    marginRight: '10px',
  },
  illustration: {
    width: '12vw',
    textAlign: 'center',
    margin: '50px 40px',
  },
  helperText: {
    color: '#4a4a4a',
  },
  root: {
    backgroundColor: 'white',
    marginTop: '-5px',
    display: 'table',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  innerContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 700,
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 50,
    paddingTop: 20,
    background: 'rgb(192,192,192,0.1)',
  },
  getStartedBtn: {
    padding: 12,
    borderRadius: 5,
    width: '40%',
  },
}));

const AccountActivated: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Box boxShadow={3} className={classes.innerContainer}>
          <br />
          <Typography variant="h2" color="primary">
            Congratulations!
          </Typography>
          <p className={classes.helperText}>Your account has been activated.</p>
          <br />
          <br />
          <Button
            size="large"
            className={classes.getStartedBtn}
            onClick={() => {
              history.push('/results');
            }}
          >
            Get Started
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default AccountActivated;
