import { FC } from 'react';
import { Box, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

interface Props {
  children: any;
}

type OwnProps = Props;

const Layout: FC<OwnProps> = (props) => {
  const { children } = props;
  const history = useHistory();
  const hideHeader = history.location.pathname.includes('login');
  return (
    <Box display="flex">
      {/* Header */}
      {!hideHeader && <Header />}
      {/* Main section */}
      <Box flexGrow={1}>
        {!hideHeader && <Toolbar />}
        {children}
      </Box>
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Layout;
