import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { color } from '../../theme/Theme';

const useStyles = makeStyles((theme) => ({
  footer: {
    height: 30,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: color.dark,
  },
  footerText: {
    fontSize: 12,
    color: color.light,
    padding: 20,
  },
  contactSupport: {
    cursor: 'pointer',
  },
}));

const Footer: FC = () => {
  const classes = useStyles();
  return (
    <Box
      data-cy="footer"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.footer}
    >
      <Typography className={classes.footerText}>{/* Left aligned content goes here */}</Typography>
      <Typography className={classes.footerText}>© Render Networks | ver 2.0</Typography>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
      <Box className={classes.contactSupport} onClick={() => (window as any).Beacon('toggle')}>
        <Typography className={classes.footerText}>⨂ Contact Support</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
