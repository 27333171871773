import { TileLayer, BitmapLayer } from 'deck.gl';
import { DeckGL } from '@deck.gl/react';
import {
  StaticMap,
  _MapContext as MapContext,
  NavigationControl,
  FullscreenControl,
} from 'react-map-gl';
import { FC, useState, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DownlaodIcon from '@material-ui/icons/GetApp';
import RenameIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

const tileUrl = '';

const useStyles = makeStyles(() => ({
  styleBtn: {
    background: 'white',
  },
  controlBtn: {
    marginBottom: 30,
    marginRight: 5,
  },
  shareBtn: {
    background: 'rgb(256,256,256,0.9)',
  },
  menuItem: {
    fontSize: 14,
  },
  popper: {
    marginTop: 5,
    opacity: 0.9,
  },
  hide: {
    pointerEvents: 'none',
  },
  listIcon: {
    minWidth: 30,
  },
}));

interface Props {
  readonly lock: boolean;
}

const Map: FC<Props> = (props) => {
  const classes = useStyles();
  const { lock } = props;
  const anchorRef = useRef(null);
  const [zoom, setZoom] = useState<number>(11);
  const [open, setOpen] = useState<boolean>(false);
  const [mapStyle, setMapStyle] = useState<string>('map');
  const layer = new TileLayer({
    id: 'control-raster',
    data: tileUrl,
    minZoom: 0,
    maxZoom: 21,
    tileSize: 256,

    // do not throttle requests
    maxRequests: 0,

    // this is terrifyingly undocumented
    // https://stackoverflow.com/questions/63385646/is-there-a-way-to-pass-basic-auth-header-options-when-retrieving-a-deck-gl-layer
    // @ts-ignore
    loadOptions: {
      headers: {
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_HTTP_USER}:${process.env.REACT_APP_HTTP_PASS}`,
        )}`,
      },
    },
    renderSubLayers: (subLayerProps) => {
      const {
        bbox: { west, south, east, north },
      } = subLayerProps.tile;

      // @ts-ignore
      return new BitmapLayer(subLayerProps, {
        // @ts-ignore
        data: null,
        image: subLayerProps.data,
        bounds: [west, south, east, north],
      });
    },
  });

  return (
    <div className={`${lock ? classes.hide : ''}`}>
      <DeckGL
        controller={!lock}
        initialViewState={{
          latitude: 37.21,
          longitude: -93.29,
          zoom,
          maxZoom: 21,
          pitch: 0,
          bearing: 0,
        }}
        // @ts-ignore
        layers={[layer]}
      >
        <StaticMap
          mapStyle={`mapbox://styles/mapbox/${
            mapStyle === 'map' ? 'streets-v11' : 'satellite-streets-v11'
          }`}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          width={500}
          height={500}
          // @ts-ignore
          ContextProvider={MapContext.Provider}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            m={3}
            p={3}
            width="18em"
            boxShadow={1}
            className={classes.shareBtn}
          >
            <Box pt={1} textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              MOH-Desing-Packagev1.5.zip
            </Box>
            <IconButton size="small" onClick={() => setOpen(!open)} ref={anchorRef}>
              <ArrowDropDownIcon />
            </IconButton>
          </Box>
          <Popper
            className={classes.popper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(!open)}>
                <MenuList>
                  <MenuItem className={classes.menuItem} onClick={(event) => console.log(event)}>
                    <ListItemIcon className={classes.listIcon}>
                      <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    Share Blueprint
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={(event) => console.log(event)}>
                    <ListItemIcon className={classes.listIcon}>
                      <DownlaodIcon fontSize="small" />
                    </ListItemIcon>
                    Download BOMBOQ (CSV)
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={(event) => console.log(event)}>
                    <ListItemIcon className={classes.listIcon}>
                      <RenameIcon fontSize="small" />
                    </ListItemIcon>
                    Rename
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
          {!lock && (
            <div className={`mapboxgl-ctrl-bottom-right ${classes.controlBtn}`}>
              <NavigationControl
                showCompass={false}
                onViewportChange={(viewport) => setZoom(viewport.zoom)}
              />
              <FullscreenControl />
              <Box>
                <ToggleButtonGroup
                  value={mapStyle}
                  className={classes.styleBtn}
                  exclusive
                  size="small"
                  onChange={(e, v) => setMapStyle(v)}
                >
                  <ToggleButton value="map" aria-label="left aligned">
                    Map
                  </ToggleButton>
                  <ToggleButton value="satellite" aria-label="centered">
                    Satellite
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </div>
          )}
        </StaticMap>
      </DeckGL>
    </div>
  );
};

export default Map;
