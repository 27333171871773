import { useEffect, useRef, FC } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { color } from '../../theme/Theme';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const OktaSignIn = require('@okta/okta-signin-widget');

const Login: FC = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const widget = new OktaSignIn({
      baseUrl: process.env.REACT_APP_OKTA_DOMAIN,
      clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
      redirectUri: `${window.location.origin}/login/callback`,
      scope: 'openid profile email',
      brandName: 'Render',
      // TODO: change this and host it on s3
      logo: 'https://avatars.slack-edge.com/2018-11-20/484931652823_4386d94d30633d94f365_88.png',
      logoText: 'Render',
      colors: {
        brand: color.primary,
      },
      features: {
        showPasswordToggleOnSignInPage: true,
        rememberMe: false,
        registration: true,
      },
      i18n: {
        en: {
          'primaryauth.title': 'Login into Render',
          'needhelp': 'Need help signing in?',
          'goback': 'Back to Login',
          'primaryauth.username.placeholder': 'Email',
          'primaryauth.username.tooltip': 'Email',
          'error.username.required': 'Please enter a email',
          'password.forgot.email.or.username.placeholder': 'Email',
          'password.forgot.email.or.username.tooltip': 'Email',
          'help': ' ',
          'primaryauth.submit': 'LOGIN',
          'registration.signup.label': 'By logging in, you are accepting our',
          'registration.signup.text': 'Terms of Service',
          'forgotpassword': 'Forgot your password?',
        },
      },
      registration: {
        click: () => {
          window.open('https://rendernetworks.com/tos/', '_blank');
        },
      },
      authParams: {
        issuer: process.env.REACT_APP_OKTA_DOMAIN,
        scopes: ['openid', 'profile'],
      },
      helpLinks: {
        help: '',
        needhelp: 'asda',
      },
    });

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then((tokens: any) => {
        oktaAuth.handleLoginRedirect(tokens);
      })
      .catch((err: any) => {
        throw err;
      });

    // eslint-disable-next-line
    return () => widget.remove();
  }, [oktaAuth]);

  return (
    <div>
      <div className="okta-widget-container" ref={widgetRef as any} />
    </div>
  );
};
export default Login;
