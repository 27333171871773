import { FC, useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
  Container,
  Chip,
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogContent,
  LinearProgress,
  DialogActions,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useHistory } from 'react-router-dom';
import FileUpload from '../components/fileUpload/FileUpload';
import { createUserAndUploadDesign } from '../service/User';
import Illustration from '../assets/images/upload.svg';
import { color } from '../theme/Theme';

const useStyles = makeStyles(() => ({
  verDivider: {
    height: '65vh',
    padding: '50px 20px 0px 20px',
  },
  helperText: {
    color: 'grey',
    marginLeft: 8,
  },
  securedChip: {
    background: '#D4EFDF',
  },
  rightSec: {
    width: '25vw',
  },
  uploadBtn: {
    padding: 12,
    borderRadius: 5,
  },
  inputField: {
    margin: '15px 5px -10px 5px',
  },
  label: {
    fontSize: 16,
    marginLeft: 7,
  },
  inputText: {
    fontSize: 15,
  },
  dialog: {
    padding: '0px 100px 20px 100px',
    margin: '20px 0px 10px 0px',
    textAlign: 'center',
  },
  dialogBtn: {
    width: '30%',
    margin: 'auto',
    marginBottom: 20,
    borderRadius: 5,
  },
  illustration: {
    width: '23vw',
    textAlign: 'center',
    margin: '50px 40px',
  },
  errorBorder: {
    border: `1px dashed ${color.warning}`,
  },
  greyBorder: {
    border: '1px dashed #ddd',
  },
  error: {
    color: color.warning,
  },
}));

const Home: FC = () => {
  const classes = useStyles();
  const [selectedFile, setFile] = useState<File | null>(null);
  const [progress, setProgressVal] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>('');
  const history = useHistory();

  const fileName = selectedFile?.name;

  const validateEmail = (emailId: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailId).toLowerCase());
  };

  const validateForm = () => {
    return firstName && lastName && email && validateEmail(email) && selectedFile;
  };

  const onConfirm = () => {
    setOpen(false);
    if (uploadError === '') {
      history.push({
        pathname: '/confirm-upload',
        state: {
          firstName,
          lastName,
          email,
          fileName,
        },
      });
    } else {
      setUploadError('');
    }
  };

  const setProgress = (evt: any) => {
    setProgressVal(Math.round((evt.loaded / evt.total) * 100));
  };

  const onUpload = async () => {
    setSubmit(true);
    if (validateForm() && selectedFile) {
      setOpen(true);

      try {
        await createUserAndUploadDesign(
          {
            user: {
              email,
              first_name: firstName,
              last_name: lastName,
            },
            filename: selectedFile.name,
          },
          selectedFile,
          setProgress,
        );
      } catch (e) {
        setUploadError(`An error occured while uploading your design: ${e.message}`);
        setProgressVal(100);
      }
    }
  };

  const uploading = progress !== 100;

  return (
    <Container>
      <Box mt={4} display="flex" justifyContent="center">
        <Grid>
          <Box p={4} pt={0}>
            <Typography variant="h2">Try Render/Scale</Typography>
          </Box>
          <Divider />
          <Grid container>
            <Grid item xs={5}>
              <Box p={4}>
                <Typography>How it works</Typography>
                <Box mb={4} />
                <p>
                  <u>Step 1:</u> Upload your design package (a zipped file, upto 20 MB).
                </p>
                <Box mb={8} />
                <p>
                  <u>Step 2:</u> Wait until we turn your design into a Render blueprint layout.
                  <span className={classes.helperText}>
                    (this usually takes anywhere between 48 hrs - 5 days).
                  </span>
                </p>
                <Box mb={8} />
                <p>
                  <u>Step 3:</u> Once ready, you receive a link to access the network design and
                  share it with others.
                </p>
                <Box mb={8} />
                <p>
                  <u>Step 4:</u> Sign up & start using Render for your organisation.
                </p>
                <img src={Illustration} className={classes.illustration} alt="uplaod" />
              </Box>
            </Grid>
            <Grid className={classes.verDivider}>
              <Divider variant="middle" orientation="vertical" />
            </Grid>
            <Grid item xs={6} className={classes.rightSec}>
              <Box p={2} pt={4}>
                <Typography className={classes.label}>Your Name</Typography>
                <Box display="flex">
                  <TextField
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                    color="secondary"
                    fullWidth
                    error={!firstName && submit}
                    inputProps={{
                      className: classes.inputText,
                      'data-cy': 'first-name',
                      endAdornment: (
                        <>
                          {!firstName && submit && (
                            <ErrorOutlineIcon htmlColor={color.warning} fontSize="small" />
                          )}
                        </>
                      ),
                    }}
                    className={classes.inputField}
                    size="small"
                    variant="outlined"
                    label="First Name"
                  />
                  <TextField
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                    color="secondary"
                    fullWidth
                    error={!lastName && submit}
                    inputProps={{
                      className: classes.inputText,
                      'data-cy': 'last-name',
                      endAdornment: (
                        <>
                          {!lastName && submit && (
                            <ErrorOutlineIcon htmlColor={color.warning} fontSize="small" />
                          )}
                        </>
                      ),
                    }}
                    className={classes.inputField}
                    size="small"
                    variant="outlined"
                    label="Last Name"
                  />
                </Box>
                {(!firstName || !lastName) && submit ? (
                  <p className={`${classes.helperText} ${classes.error}`}>
                    Please enter a valid first and last name
                  </p>
                ) : (
                  <p className={classes.helperText}>What should we call you?</p>
                )}
                <Box mr={2}>
                  <Typography className={classes.label}>Your work email address</Typography>
                  <TextField
                    type="email"
                    color="secondary"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    error={(!email || !validateEmail(email)) && submit}
                    inputProps={{
                      className: classes.inputText,
                      'data-cy': 'email',
                      endAdornment: (
                        <>
                          {(!email || !validateEmail(email)) && submit && (
                            <ErrorOutlineIcon htmlColor={color.warning} fontSize="small" />
                          )}
                        </>
                      ),
                    }}
                    className={classes.inputField}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Email Address"
                    placeholder="For e.g. name@company.com"
                  />
                  {(!email || !validateEmail(email)) && submit ? (
                    <p className={`${classes.helperText} ${classes.error}`}>
                      Please enter a valid email
                    </p>
                  ) : (
                    <p className={classes.helperText}>
                      We need this to send you details of the processed design
                    </p>
                  )}
                </Box>
                <Box mb={6} />
                <Typography className={classes.label}>Upload Design Package *</Typography>
                <Box textAlign="right" p={2}>
                  {!selectedFile && submit ? (
                    <ErrorOutlineIcon htmlColor={color.warning} fontSize="small" />
                  ) : (
                    <Chip
                      size="small"
                      className={classes.securedChip}
                      icon={<LockOutlinedIcon color="primary" />}
                      label="Secured"
                      disabled
                    />
                  )}
                </Box>
                <Box
                  className={!selectedFile && submit ? classes.errorBorder : classes.greyBorder}
                  display="flex"
                  data-cy="upload-box"
                  justifyContent="center"
                >
                  <FileUpload acceptedTypes=".zip" onFileSelect={setFile} />
                </Box>
                {!selectedFile && submit ? (
                  <p className={`${classes.helperText} ${classes.error}`}>
                    Design package needs to be less than 20MB and in a ZIP format.
                  </p>
                ) : (
                  <p className={classes.helperText}>Only zipped file of upto 20 MB is accepted</p>
                )}
                <Box mb={8} />
                <p className={classes.helperText}>
                  By uploading this file, you confirm that you agree to our <u>Terms of Service</u>{' '}
                  and consent to the storing and processing of your design file as described in our{' '}
                  <u>Privacy Policy</u>.
                </p>
                <Box mb={6} />
                <Button
                  color="primary"
                  onClick={onUpload}
                  data-cy="upload-button"
                  className={classes.uploadBtn}
                  fullWidth
                  size="large"
                >
                  Upload
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog fullWidth open={open}>
        <DialogContent className={classes.dialog}>
          {!uploadError ? (
            <>
              <Typography>Uploading your design file</Typography>
              {uploading ? (
                <p data-cy="upload-status">Please wait, and don’t close the window.</p>
              ) : (
                <p data-cy="upload-status">File uploaded</p>
              )}
              <Grid container>
                <Grid xs={11} spacing={10}>
                  <Box pr={2} pt={2}>
                    <LinearProgress
                      data-cy="upload-percent"
                      variant="determinate"
                      value={progress}
                    />
                  </Box>
                </Grid>
                <Grid xs={1}>
                  <Box pl={2}>{progress}%</Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography>Upload error</Typography>
              <p data-cy="upload-status">{uploadError}</p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirm}
            disabled={uploading}
            color="primary"
            className={classes.dialogBtn}
            fullWidth
            data-cy="done-button"
            variant="text"
            size="large"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Home;
