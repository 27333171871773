import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const layerIndex = {
  labelAboveInput: 0x100,
  iconAboveForm: 0x200,
  dialogAboveForm: 0x300,
  autoCompletePopUp: 0x400,
};

export const color = {
  primary: '#DA5729',
  secondary: '#fafafa',
  secondary100: 'rgba(0, 0, 0, 0.12)',
  secondary200: 'rgba(0, 0, 0, 0.87)',
  secondary300: '#a9a9a9',
  charcoal: '#4a4a4a',
  secondary400: 'rgba(0, 0, 0, .5)',
  secondary500: 'rgba(0, 0, 0, 0.6)',
  light: '#ffffff',

  dark: '#202020',
  dark1000: '#1e1e28',
  dark900: '#666666',
  dark800: '#9E9E9E',
  dark800Hover: '#4A4A4A',
  dark700: '#EEEFF1',
  dark600: '#F6F6F6',

  warning: '#B04A4A',
};

export const themeConstants = {
  inputBackgroundColor: color.dark600,
  fontSize: {
    lg: 16,
    md: 16,
    sm: 14,
    xs: 12,
    xs2: 10,
    xs3: 8,
  },
};

export const themeOptions: ThemeOptions = {
  spacing: 4,
  palette: {
    primary: {
      main: color.primary,
    },
    secondary: {
      main: color.dark800,
    },
    error: {
      main: color.warning,
    },
  },
  typography: {
    fontFamily: ['Futura', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 68,
      fontWeight: 500,
      lineHeight: 'normal',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 'normal',
      color: color.dark,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: '0.5px',
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.15,
      fontFamily: 'Futura',
      letterSpacing: '.5px',
      color: color.secondary200,
    },
    h5: {
      fontSize: 27.2,
      fontWeight: 500,
      lineHeight: 'normal',
      fontStyle: 'normal',
      color: color.primary,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      color: color.secondary200,
    },
    subtitle1: {
      fontSize: '1.2rem',
      lineHeight: 1.2,
      color: color.secondary500,
    },
    subtitle2: {
      fontSize: '18px',
      lineHeight: 1.32,
      fontWeight: 500,
      color: color.secondary200,
    },
    body1: {
      fontSize: 19,
      lineHeight: 1.5,
      fontWeight: 300,
      color: color.secondary200,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.43,
      color: color.secondary200,
      fontFamily: 'Futura',
    },
    caption: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.18,
      letterSpacing: 0.4,
      color: color.primary,
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14,
      letterSpacing: 0.8,
      color: color.secondary200,
    },
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: color.primary,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&$checked': {
          color: color.primary,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Roboto',
        fontSize: 16,
        '&$focused': {
          color: 'grey',
        },
        '&$error': {
          color: color.warning,
        },
      },
      formControl: {
        transform: 'translate(10px, 34px)',
        zIndex: layerIndex.labelAboveInput,
      },
      shrink: {
        zIndex: 0,
      },
    },
    MuiInput: {
      input: {
        height: '30px',
        padding: '0.5em',
        fontSize: '1rem',
        borderStyle: 'none',
      },
      inputMultiline: {
        height: '30px',
        padding: '0.5em',
        fontSize: '1rem',
      },
    },
    MuiSelect: {
      select: {
        padding: '14px 10px 14px 10px',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        fontFamily: 'Roboto',
        color: color.dark800,
        '&$error': {
          marginTop: 0,
          paddingTop: 5,
          color: color.warning,
        },
        '&$required': {
          marginTop: 0,
          paddingTop: 5,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Roboto',
        color: 'rgba(0,0,0,0.6)',
        fontSize: '15px',
      },
    },

    MuiButton: {
      root: {
        textTransform: 'none',
      },
      textPrimary: {
        backgroundColor: color.primary,
        color: color.light,
        textAlign: 'center',
        borderRadius: '0',
        '&:hover': {
          backgroundColor: color.primary,
        },
        '&$disabled': {
          opacity: '.6',
          color: color.light,
        },
      },
      text: {
        backgroundColor: color.primary,
        color: color.light,
        textAlign: 'center',
        borderRadius: '0',
        '&:hover': {
          backgroundColor: color.primary,
        },
      },
      textSecondary: {
        backgroundColor: color.secondary,
        color: color.primary,
        textAlign: 'center',
        borderRadius: '0',
      },
    },

    MuiIconButton: {
      root: {
        borderRadius: '0px',
      },
    },

    MuiMenuItem: {
      root: {
        fontWeight: 400,
        '&$selected': {
          fontWeight: 400,
        },
      },
    },

    MuiTypography: {
      h1: {
        '@media only screen and (max-width: 480px)': {
          fontSize: '2rem',
        },

        /* Medium devices (landscape tablets, 768px and up) */
        '@media only screen and (min-width:480px) and (max-width: 992px)': {
          fontSize: '2.5rem',
        },
      },
      h5: {
        /* Small devices (portrait tablets and large phones, 600px and up) */
        '@media only screen and (max-width: 600px)': {
          fontSize: '1.2rem',
        },
      },
    },
  },
};

const theme = createMuiTheme(themeOptions);

export default theme;
