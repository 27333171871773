import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, MenuItem, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  sidebar: {
    height: '40vh',
    background: 'white',
    borderRadius: 4,
  },
  sidebarHeader: {
    fontSize: 17,
    padding: '8px 0px',
  },
  menuItem: {
    fontSize: 16,
    color: '#3F3F3F',
  },
}));

const UserListMenu: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.sidebar} p={4}>
      <Typography className={classes.sidebarHeader}>User Management</Typography>
      <Divider />
      <br />
      <MenuItem className={classes.menuItem}>Users</MenuItem>
    </Box>
  );
};

export default UserListMenu;
