import { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, AppBar, Button, Avatar, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import RenderLogo from '../../assets/images/RenderLogo.svg';
import { color } from '../../theme/Theme';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '15px 0px',
    height: 60,
  },
  logo: {
    width: 100,
    height: 23,
    marginLeft: 10,
  },
  help: {
    fontSize: 16,
    color: color.primary,
    margin: 20,
    fontWeight: 'bold',
  },
  clickable: {
    cursor: 'pointer',
  },
  avatar: {
    height: 30,
    width: 30,
    marginLeft: 15,
    fontSize: 14,
    backgroundColor: color.primary,
    cursor: 'auto',
  },
  logoutBtn: {
    paddingTop: 3,
  },
}));

const Header: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<{ name: string } | null>(null);
  const [logoutText, setLogoutText] = useState<string>('Logout');

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info: any) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes
  return (
    <Box display="flex">
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Box data-cy="header" display="flex" justifyContent="space-between" px={5}>
          <img
            onClick={() => history.push('/')}
            className={`${classes.logo} ${classes.clickable}`}
            src={RenderLogo}
            alt="Render Logo"
          />
          <Box className={classes.clickable} display="flex">
            {authState.isAuthenticated && (
              <>
                <Button
                  className={classes.logoutBtn}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setLogoutText('Logging out...');
                    oktaAuth.signOut('/login');
                  }}
                >
                  {logoutText}
                </Button>
                <Tooltip title={userInfo ? userInfo.name : ''}>
                  <Avatar className={classes.avatar}>
                    {userInfo && userInfo.name
                      ? userInfo.name.split(' ').map((n) => n.charAt(0))
                      : ''}
                  </Avatar>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
};

export default Header;
