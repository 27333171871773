/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  getConfig,
  QueryPath,
  getUrl,
  processError,
  MultipartFormData,
  prepareMultiPartForm,
} from './Helper';
import axios from './Axios';
import HttpContentType from './HttpContentType';

type ServiceOptions = {
  baseUrl?: string;
  contentType?: HttpContentType;
  onUploadProgress?: (event: any) => void;
};

export const fetcher = async (url: string, qp: QueryPath): Promise<any> => {
  return (await axios.get(getUrl(qp, url), getConfig())).data;
};

export async function getService<T = void>(qp: QueryPath, options?: ServiceOptions): Promise<T> {
  const path = getUrl(qp, options?.baseUrl);

  try {
    const res = await axios.get(path, getConfig());
    console.log(`ApiService.get ${path} @Result`, {
      path,
      result: res,
    });
    return res.data;
  } catch (err) {
    console.error(`ApiService.get ${path} @Error`, {
      path,
      error: err,
    });
    throw processError(err);
  }
}

export async function postService<T = void>(
  qp: QueryPath,
  body: any,
  options?: ServiceOptions,
): Promise<T> {
  const path = getUrl(qp, options?.baseUrl);

  try {
    const res = await axios.post(path, body, {
      ...getConfig(options?.contentType ?? HttpContentType.Json),
      onUploadProgress: options?.onUploadProgress,
    });
    console.log(`ApiService.post ${path} @Result`, {
      path,
      result: res,
    });
    return res.data;
  } catch (err) {
    console.error(`ApiService.post ${path} @Error`, {
      path,
      error: err,
    });
    throw processError(err);
  }
}

export async function putService<T = void>(
  qp: QueryPath,
  body: any,
  options?: ServiceOptions,
): Promise<T> {
  const path = getUrl(qp, options?.baseUrl);

  try {
    const res = await axios.put(path, body, {
      ...getConfig(options?.contentType ?? HttpContentType.Json),
      onUploadProgress: options?.onUploadProgress,
    });
    console.log(`ApiService.put ${path} @Result`, {
      path,
      result: res,
    });
    return res.data;
  } catch (err) {
    console.error(`ApiService.put ${path} @Error`, {
      path,
      error: err,
    });
    throw processError(err);
  }
}

export async function deleteService<T = void>(qp: QueryPath, options?: ServiceOptions): Promise<T> {
  const path = getUrl(qp, options?.baseUrl);

  try {
    const res = await axios.delete(path, getConfig());
    console.log(`ApiService.delete ${path} @Result`, {
      path,
      result: res,
    });
    return res.data;
  } catch (err) {
    console.error(`ApiService.delete ${path} @Error`, {
      path,
      error: err,
    });
    throw processError(err);
  }
}

export async function postMultipartService<T = void>(
  qp: QueryPath,
  data: MultipartFormData[],
  options?: ServiceOptions,
): Promise<T> {
  const path = getUrl(qp, options?.baseUrl);

  try {
    const formData = prepareMultiPartForm(data);
    const res = await axios.post(path, formData, {
      ...getConfig(options?.contentType ?? HttpContentType.Json),
      onUploadProgress: options?.onUploadProgress,
    });
    console.log(`ApiService.postMultipart ${path} @Result`, {
      path,
      result: res,
    });
    return res.data;
  } catch (err) {
    console.error(`ApiService.postMultipart ${path} @Error`, {
      path,
      error: err,
    });
    throw processError(err);
  }
}
