import { makeStyles } from '@material-ui/core';
import { color } from '../../theme/Theme';

const onboardingStyles = makeStyles(() => ({
  verDivider: {
    height: '65vh',
    padding: '50px 20px 0px 20px',
  },
  formControl: {
    width: '100%',
    marginTop: 15,
    marginLeft: 5,
  },
  dropdown: {
    height: 38,
    fontSize: 14,
    marginBottom: 3,
  },
  helperText: {
    color: 'grey',
    marginLeft: 8,
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  rightSec: {
    width: '25vw',
  },
  submitBtn: {
    padding: 12,
    borderRadius: 5,
    width: '40%',
  },
  inputField: {
    margin: '15px 5px -10px 5px',
  },
  label: {
    fontSize: 16,
    marginLeft: 7,
  },
  inputText: {
    fontSize: 15,
    fontFamily: 'Roboto',
  },
  errorBorder: {
    border: `1px dashed ${color.warning}`,
  },
  greyBorder: {
    border: '1px dashed #ddd',
  },
  error: {
    color: `${color.warning} !important`,
  },
  phoneContainer: {
    marginBottom: 5,
    marginLeft: 5,
    paddingRight: 0,
    height: 38,
  },
  phoneInput: {
    width: '100% !important',
    height: '38px !important',
    backgroundColor: '#FAFAFA !important',
  },
  phoneHelper: {
    marginTop: -3,
  },
  iconBtn: {
    margin: 5,
  },
  showHideBtn: {
    color: 'grey',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  showBtn: {
    height: 38,
  },
  passwordField: {
    margin: '5px 0px -10px 5px',
  },
  option: {
    fontSize: 14,
  },
  terms: {
    fontSize: 16,
    color: 'grey',
    fontFamily: 'Roboto',
  },
  passwordStength: {
    fontSize: 14,
    color: 'grey',
    paddingLeft: 14,
    fontFamily: 'Roboto',
  },
  check: {
    backgroundColor: '#86C67C !important',
  },
  uncheck: {
    backgroundColor: '#ddd !important',
  },
  tosLink: {
    color: 'grey',
  },
  header: {
    fontSize: 36,
  },
  secondaryText: {
    fontSize: 16,
    fontFamily: 'Roboto',
  },
  roboto: {
    fontFamily: 'Roboto',
  },
}));

export default onboardingStyles;
