const mockData = [
  {
    id: 1,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 2,
    lastName: 'Lannister',
    firstName: 'Cersei',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 3,
    lastName: 'Lannister',
    firstName: 'Jaime',
    email: 'w.houston@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 4,
    lastName: 'Stark',
    firstName: 'Arya',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Disabled',
  },
  {
    id: 5,
    lastName: 'Targaryen',
    firstName: 'Daenerys',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 6,
    lastName: 'Melisandre',
    firstName: 'Shubham',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 7,
    lastName: 'Clifford',
    firstName: 'Ferrara',
    email: 'w.houston@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 8,
    lastName: 'Frances',
    firstName: 'Rossini',
    email: 'a.keys@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 9,
    lastName: 'Roxie',
    firstName: 'Harvey',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 10,
    lastName: 'Roxie',
    firstName: 'Harvey',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Disabled',
  },
  {
    id: 11,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 12,
    lastName: 'Lannister',
    firstName: 'Cersei',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 13,
    lastName: 'Lannister',
    firstName: 'Jaime',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 14,
    lastName: 'Stark',
    firstName: 'Arya',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 15,
    lastName: 'Targaryen',
    firstName: 'Daenerys',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 16,
    lastName: 'Melisandre',
    firstName: 'Shubham',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 17,
    lastName: 'Clifford',
    firstName: 'Ferrara',
    email: 'w.houston@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 18,
    lastName: 'Frances',
    firstName: 'Rossini',
    email: 'a.keys@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Disabled',
  },
  {
    id: 19,
    lastName: 'Roxie',
    firstName: 'Harvey',
    email: 'w.houston@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 20,
    lastName: 'Roxie',
    firstName: 'Harvey',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 1,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 2,
    lastName: 'Lannister',
    firstName: 'Cersei',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 3,
    lastName: 'Lannister',
    firstName: 'Jaime',
    email: 'w.houston@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 4,
    lastName: 'Stark',
    firstName: 'Arya',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Disabled',
  },
  {
    id: 5,
    lastName: 'Targaryen',
    firstName: 'Daenerys',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 6,
    lastName: 'Melisandre',
    firstName: 'Shubham',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 7,
    lastName: 'Clifford',
    firstName: 'Ferrara',
    email: 'w.houston@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 8,
    lastName: 'Frances',
    firstName: 'Rossini',
    email: 'a.keys@rendernetworks.com',
    userType: 'Admin',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 9,
    lastName: 'Roxie',
    firstName: 'Harvey',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 10,
    lastName: 'Roxie',
    firstName: 'Harvey',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Disabled',
  },
  {
    id: 11,
    lastName: 'Snow',
    firstName: 'Jon',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 12,
    lastName: 'Lannister',
    firstName: 'Cersei',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 13,
    lastName: 'Lannister',
    firstName: 'Jaime',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 14,
    lastName: 'Stark',
    firstName: 'Arya',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 15,
    lastName: 'Targaryen',
    firstName: 'Daenerys',
    email: 'w.houston@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
  {
    id: 16,
    lastName: 'Melisandre',
    firstName: 'Shubham',
    email: 'a.keys@rendernetworks.com',
    userType: 'Standard',
    lastActive: 'Jan 21 2020',
    status: 'Active',
  },
];

export default mockData;
