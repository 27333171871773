import { Box } from '@material-ui/core';
import { useState, FC } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AccountCreationForm from '../components/blueprints/AccountCreationForm';
import Map from '../components/blueprints/Map';

const mockData = {
  firstName: 'Shubham',
  lastName: 'Singh',
  email: 'shubham.singh@render.com',
};

const ViewBlueprints: FC = () => {
  const [open, setOpen] = useState<boolean>(true);
  const { authState } = useOktaAuth();
  // Closing signup form if already loggedin
  if (authState.isAuthenticated && open) {
    setOpen(false);
  }
  const onFormSubmit = (orgName: string, phone: string, password: string) => {
    setOpen(false);
    // API call here
    console.log(orgName, phone, password);
  };
  return (
    <Box m={4} position="relative" height="80vh">
      <Map lock={open} />
      <AccountCreationForm onFormSubmit={onFormSubmit} open={open} data={mockData} />
    </Box>
  );
};

export default ViewBlueprints;
