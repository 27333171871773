import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { FC } from 'react';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  primaryBtn: {
    fontSize: 14,
    padding: '0px 15px',
    borderRadius: 3,
    margin: '20px 0 20px 20px',
    height: 35,
    background: '#535D6D',
    '&:disabled': {
      color: 'white',
      background: '#a9a9a9',
    },
    '&:hover': {
      background: '#36454f',
      border: 'none',
    },
  },
  secondaryBtn: {
    fontSize: 14,
    padding: '0px 15px',
    borderRadius: 3,
    margin: '20px 0 20px 10px',
    height: 35,
    border: '1px solid #535D6D',
    color: '#535D6D',
    background: 'white',
    '&:hover': {
      color: '#36454f',
      borderColor: '#36454f',
      background: '#eee',
    },
  },
  title: {
    fontSize: 18,
    color: '#3F3F3F',
    marginBottom: 5,
  },
  time: {
    fontSize: 16,
    color: '#3F3F3F',
    fontFamily: 'Roboto',
    marginBottom: 5,
  },
  status: {
    fontFamily: 'Roboto',
    fontSize: 16,
  },
  statusIcon: {
    marginLeft: 10,
    verticalAlign: 'middle',
  },
}));

interface PropsFromParent {
  title: string;
  lastUpdated: number;
  status: string;
  downloadHandler: () => void;
  refreshHandler: () => void;
}

const Report: FC<PropsFromParent> = (props) => {
  const classes = useStyles();
  const { title, lastUpdated, status, downloadHandler, refreshHandler } = props;

  const timeFormatter = (time: number) => {
    if (time === 0 || status === 'Successful') return 'now';
    if (time < 1) return `${Math.floor(time * 60)} mins ago`;
    if (time > 24) return `${Math.floor(time / 24)} days ago`;
    return `${time} hours ago`;
  };

  const getStatusIcon = (s: string) => {
    switch (s) {
      case 'In progress':
        return (
          <CircularProgress
            className={classes.statusIcon}
            size={20}
            thickness={5}
            color="primary"
          />
        );
      case 'Successful':
        return <CheckIcon htmlColor="#4FCD89" className={classes.statusIcon} />;
      case 'Failed':
        return <ErrorOutlineIcon htmlColor="#EF6E50" className={classes.statusIcon} />;
      default:
        return null;
    }
  };

  return (
    <Box m={4}>
      <Card elevation={2} className={classes.card}>
        <Box p={8} pb={4}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography
            className={classes.time}
            data-cy={title === 'Tickets Report' ? 'tickets-updatedTime' : 'asbuilt-updatedTime'}
          >
            Last Updated {lastUpdated || lastUpdated === 0 ? timeFormatter(lastUpdated) : 'Loading'}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Button
              size="large"
              data-cy={title === 'Tickets Report' ? 'tickets-download' : 'asbuilt-download'}
              disabled={status === 'In progress'}
              className={classes.primaryBtn}
              onClick={downloadHandler}
            >
              {status === 'Failed' ? 'DOWNLOAD PREVIOUS REPORT' : 'DOWNLOAD REPORT'}
            </Button>
            <Button
              size="large"
              data-cy={title === 'Tickets Report' ? 'tickets-refresh' : 'asbuilt-refresh'}
              disabled={status === 'In progress'}
              className={classes.secondaryBtn}
              onClick={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                status === 'Failed' ? () => (window as any).Beacon('toggle') : refreshHandler
              }
            >
              {status === 'Failed' ? 'CONTACT SUPPORT' : 'REFRESH REPORT'}
            </Button>
          </Box>
          {!!status && (
            <Box
              p={6}
              className={classes.status}
              data-cy={title === 'Tickets Report' ? 'tickets-status' : 'asbuilt-status'}
            >
              Refresh Status: <strong>{status}</strong>
              {getStatusIcon(status)}
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default Report;
