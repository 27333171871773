import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { color } from '../../theme/Theme';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: color.light,
  },
}));

interface PropsFromParent {
  open: boolean;
}

type OwnProps = PropsFromParent;

const Loader: FC<OwnProps> = ({ open }) => {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;
