import { postService, putService } from './ApiService';

type UserFilenameSpec = {
  user: {
    email: string;
    first_name: string;
    last_name: string;
  };
  filename: string;
};

type CreateUserResponse = {
  url: string;
};

export async function createUserAndUploadDesign( // eslint-disable-line import/prefer-default-export
  body: UserFilenameSpec,
  file: File,
  onUploadProgress: (e: any) => void,
): Promise<void> {
  const { url } = await postService<CreateUserResponse>(
    {
      route: ['user', 'create_user_with_upload_link'],
    },
    body,
  );

  await putService({}, file, {
    baseUrl: url,
    onUploadProgress,
  });
}
