import { FC, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  Typography,
  Button,
  makeStyles,
  DialogTitle,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ErrorOutline } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { color } from '../../theme/Theme';
import 'react-phone-input-2/lib/style.css';

const useStyles = makeStyles(() => ({
  dialogBtn: {
    marginTop: 30,
    borderRadius: 5,
    marginBottom: 10,
    height: 40,
  },
  primaryText: {
    fontSize: 20,
  },
  secondaryText: {
    fontSize: 13,
    color: 'grey',
    marginBottom: 5,
  },
  inputField: {
    margin: '5px 0px 20px 5px',
    maxWidth: '98%',
  },
  passwordField: {
    margin: '5px 0px -10px 5px',
    maxWidth: '98%',
  },
  label: {
    fontSize: 16,
    marginLeft: 7,
  },
  inputText: {
    fontSize: 15,
  },
  showBtn: {
    height: 38,
  },
  showHideBtn: {
    color: 'grey',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  iconBtn: {
    margin: 5,
  },
  error: {
    color: color.warning,
  },
  helperText: {
    color: 'grey',
    marginLeft: 8,
  },
  phoneContainer: {
    marginBottom: 5,
    marginLeft: 5,
    paddingRight: 8,
    height: 38,
  },
  phoneInput: {
    width: '100% !important',
    height: '38px !important',
    backgroundColor: 'white !important',
  },
  errorBorder: {
    borderColor: color.warning,
  },
  dialogAction: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  loginBtn: {
    color: color.primary,
    fontSize: 14,
  },
  loginLink: {
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      color: 'black',
    },
  },
}));

interface PropsFromParent {
  readonly data: any; // will fix this once the API contract is final
  readonly onFormSubmit: (orgName: string, phone: string, password: string) => void;
  readonly open: boolean;
}

const AccountCreationForm: FC<PropsFromParent> = (props) => {
  const classes = useStyles();
  const { data, onFormSubmit, open } = props;
  const [submit, setSubmit] = useState<boolean>(false);
  const [visibility, setVisibilty] = useState<boolean>(false);
  const [orgName, setOrgName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const history = useHistory();

  const validatePassword = (pw: string) => {
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    return regex.test(pw);
  };

  const onConfirm = () => {
    setSubmit(true);
    if (orgName && phone && validatePassword(password)) {
      onFormSubmit(orgName, phone, password);
    }
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>
        <Box mb={2} />
        <Typography className={classes.primaryText}>
          Hey {data.firstName}, just a few more details
        </Typography>
        <Typography className={classes.secondaryText}>
          You need to have a Render account to view/share your blueprint.
        </Typography>
        <Divider />
      </DialogTitle>
      <Box px={24} pt={4}>
        {/* <DialogContent> */}
        <Typography className={classes.label}>Email address (verified)</Typography>
        <TextField
          type="email"
          color="primary"
          disabled
          inputProps={{ className: classes.inputText }}
          className={classes.inputField}
          fullWidth
          defaultValue={data.email}
          size="small"
          variant="outlined"
        />
        <Typography className={classes.label}>Name of your Organization*</Typography>
        <TextField
          type="text"
          color="secondary"
          onChange={(e) => setOrgName(e.target.value)}
          value={orgName}
          error={!orgName && submit}
          InputProps={{
            className: classes.inputText,
            endAdornment: (
              <>
                {!orgName && submit && <ErrorOutline htmlColor={color.warning} fontSize="small" />}
              </>
            ),
          }}
          className={classes.inputField}
          fullWidth
          size="small"
          variant="outlined"
          helperText={!orgName && submit ? 'Please enter your organization name' : ''}
          FormHelperTextProps={{ color: color.warning }}
        />
        <Typography className={classes.label}>Phone Number*</Typography>
        <PhoneInput
          containerClass={classes.phoneContainer}
          inputClass={classes.phoneInput}
          enableSearch
          disableSearchIcon
          preferredCountries={['au', 'us']}
          country="au"
          isValid={!(!phone && submit)}
          searchPlaceholder="Search"
          value={phone}
          inputProps={{
            name: 'phone',
          }}
          onChange={(p) => setPhone(p)}
        />
        {!phone && submit && (
          <Box fontSize={12} ml={4} color={color.warning}>
            Please enter a valid phone number
          </Box>
        )}
        <Box mb={4} />
        <Typography className={classes.label}>Password*</Typography>
        <OutlinedInput
          color="secondary"
          margin="dense"
          value={password}
          error={!validatePassword(password) && submit}
          onChange={(e) => setPassword(e.target.value)}
          type={visibility ? 'text' : 'password'}
          inputProps={{
            className: classes.inputText,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                className={classes.showBtn}
                onClick={() => {
                  setVisibilty(!visibility);
                }}
              >
                {/* {visibility ? <Visibility /> : <VisibilityOff />} */}
                {visibility ? (
                  <Typography
                    className={`${classes.showHideBtn} ${
                      !validatePassword(password) && submit ? classes.error : ''
                    }`}
                    variant="caption"
                  >
                    Hide
                    <VisibilityOff className={classes.iconBtn} fontSize="small" />
                  </Typography>
                ) : (
                  <Typography
                    className={`${classes.showHideBtn} ${
                      !validatePassword(password) && submit ? classes.error : ''
                    }`}
                    variant="caption"
                  >
                    Show
                    <Visibility className={classes.iconBtn} fontSize="small" />
                  </Typography>
                )}
              </IconButton>
            </InputAdornment>
          }
          className={classes.passwordField}
          fullWidth
        />
        <Box
          fontSize={12}
          mt={3}
          ml={4}
          color={!validatePassword(password) && submit ? color.warning : color.dark800}
        >
          Password must be minimum 8 characters with at least one upper case, one lower case, one
          number and a special character.
        </Box>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={onConfirm}
            disabled={false}
            color="primary"
            className={classes.dialogBtn}
            fullWidth
            variant="text"
            size="large"
          >
            Create Account
          </Button>
          <Typography className={classes.loginBtn}>
            Already have a account?
            <span
              className={classes.loginLink}
              onClick={() => {
                history.push('/login');
              }}
            >
              <u>Login</u>
            </span>
          </Typography>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AccountCreationForm;
