import { Box, Typography, makeStyles } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher, getService } from '../service/ApiService';
import Report from '../components/report/Report';

const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    padding: '20px 10px 10px 10px',
    marginTop: 10,
    margin: 40,
    width: '780px',
    border: '1px solid #eee',
  },
  title: {
    paddingLeft: 20,
    fontSize: 20,
    color: '#3F3F3F',
  },
  header: {
    paddingLeft: 40,
    fontSize: 24,
    marginBottom: 20,
    color: '#3F3F3F',
  },
}));

enum ReportType {
  ticket = 'ticket_output',
  asBuilt = 'asset_output',
}

// TODO: Make these variables useState hooks in the actual component
// Currently, there's some issues around refresh status being slow to update,
// caused by the implementation of useStatusData.
let ticketStatus = '';
let asBuiltStatus = '';

const Reports: FC = () => {
  const classes = useStyles();
  const [ticketInterval, setTicketInterval] = useState<number | undefined>();
  const [asBuiltInterval, setAsBuiltInterval] = useState<number | undefined>();
  const [ticketTaskId, setTicketTaskId] = useState<string>('');
  const [asBuilltTaskId, setAsBuiltTaskId] = useState<string>('');
  // TODO replace this with env variable
  const apiUrl = 'https://api-scale-ohio.rendernetworks.com/demo1scale/okta/views';

  // Function to get status from the worker task id, with polling enabled
  const useStatusData = (type: string) => {
    const { data, error } = useSWR(
      [`${apiUrl}/worker_status/${type === ReportType.ticket ? ticketTaskId : asBuilltTaskId}`],
      ticketTaskId || asBuilltTaskId ? (url) => fetcher(url, {}) : () => {},
      {
        refreshInterval: type === ReportType.ticket ? ticketInterval : asBuiltInterval,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        refreshWhenHidden: true,
        dedupingInterval: 0,
      },
    );
    if (type === ReportType.ticket && data) {
      ticketStatus = data.state;
    } else if (data) {
      asBuiltStatus = data.state;
    }
    return {
      data,
      loading: !error && !data,
      error,
    };
  };

  const setPollingInterval = (data: any, interval: any, setter: (duration: number) => void) => {
    if (data) {
      if ((data.state === 'STARTED' || data.state === 'PENDING') && interval !== 1000) {
        setter(1000);
      } else if ((data.state === 'FINISHED' || data.state === 'FAILED') && interval !== 0) {
        setter(0);
      }
    }
  };

  // Function to call API which returns age of the reports
  const useAgeData = (type: string) => {
    const { data, error } = useSWR(
      [`${apiUrl}/report_age?sector_id=1&report_type=${type}`],
      (url) => fetcher(url, {}),
    );
    return {
      age: data ? data.age_in_hours : '',
      loading: !error && !data,
      error,
    };
  };

  // Function to call API which returns download link for the reports
  const getDownloadLink = (type: string) => {
    getService(
      {
        route: ['sectors', '1', type],
      },
      { baseUrl: apiUrl },
    )
      .then((r: any) => {
        // download the file
        window.open(r.url);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  // Function to call API which refreshes reports and returns the task id for tracking its status
  const refreshReport = (type: string) => {
    getService(
      {
        route: ['refresh_report'],
        query: {
          'sector_id': 1,
          'report_type': type,
        },
      },
      { baseUrl: apiUrl },
    )
      .then((r: any) => {
        if (type === ReportType.ticket) {
          setTicketTaskId(r.task);
        } else {
          setAsBuiltTaskId(r.task);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const ticketData = useStatusData(ReportType.ticket);
  const asBuiltData = useStatusData(ReportType.asBuilt);

  useEffect(() => {
    setPollingInterval(ticketData.data, ticketInterval, setTicketInterval);
  }, [ticketData]);

  useEffect(() => {
    setPollingInterval(asBuiltData.data, asBuiltInterval, setAsBuiltInterval);
  }, [asBuiltData]);

  const ticketReportAge = useAgeData(ReportType.ticket);
  const asBuiltReportAge = useAgeData(ReportType.asBuilt);

  const handleRefresh = (type: string) => {
    refreshReport(type);
    // For changing the status instantaneously
    if (type === ReportType.ticket) {
      ticketStatus = 'STARTED';
    } else {
      asBuiltStatus = 'STARTED';
    }
  };

  // Function to map API status with frontend status
  const statusMapper = (status: string) => {
    switch (status) {
      case 'STARTED':
      case 'PENDING':
        return 'In progress';
      case 'FINISHED':
        return 'Successful';
      case 'FAILED':
        return 'Failed';
      default:
        return '';
    }
  };

  return (
    <Box mt={15}>
      <Typography className={classes.header}>Reports</Typography>
      <Box className={classes.root}>
        <Typography className={classes.title}>Progressive Data Reports</Typography>
        <Report
          title="Tickets Report"
          lastUpdated={ticketReportAge.age}
          status={ticketStatus ? statusMapper(ticketStatus) : ''}
          downloadHandler={() => getDownloadLink('export_tasks_csv')}
          refreshHandler={() => handleRefresh(ReportType.ticket)}
        />
        <Report
          title="As-built Report"
          lastUpdated={asBuiltReportAge.age}
          status={asBuiltStatus ? statusMapper(asBuiltStatus) : ''}
          downloadHandler={() => getDownloadLink('export_assets_csv')}
          refreshHandler={() => handleRefresh(ReportType.asBuilt)}
        />
      </Box>
    </Box>
  );
};

export default Reports;
